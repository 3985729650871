import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/poradnik/#przeziebienie"> &gt; Poradnik(Przeziębienie)</a>
            <a
              href="/poradnik/przeziebienie/cztery_fazy_rozwoju_przeziebienia/"
              className="sel"
            >
              {" "}
              &gt; Cztery fazy rozwoju przeziębienia
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/shutterstock_87834502.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Cztery fazy rozwoju przeziębienia</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <b>
                    Badania dowodzą, że w okresie jesienno-zimowym każdego z
                    nas, przynajmniej raz, dopada przeziębienie. Senność,
                    przygnębienie, rozdrażnienie, zatkany nos, drapanie w gardle
                    i osłabienie są zwiastunami nadchodzącej choroby. Co jeszcze
                    powinno nas zaniepokoić? Z jakimi symptomami choroby należy
                    udać się do lekarza? Poznaj cztery fazy rozwoju
                    przeziębienia, by rozpocząć skuteczną walkę z infekcjami.
                  </b>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Przeziębienie dzieli się na cztery fazy. Symptomami
                    pojawiającymi się w pierwszej fazie są: ogólne
                    rozdrażnienie, dreszcze, pogorszenie samopoczucia i
                    zmęczenie. Często takie objawy nie budzą w nas obaw, gdyż
                    powodów spadku energii może być wiele, a nie każdy z nas
                    zdaje sobie sprawę, że organizm rozpoczął walkę z wirusami.
                    By wzmocnić{" "}
                    <a href="/slowniczek/#uklad_immunologiczny" target="_blank">
                      układ immunologiczny
                    </a>{" "}
                    sięgamy po witaminę C lub korzystamy z domowych sposobów na
                    rozgrzanie organizmu, np. herbaty z miodem, czosnkiem lub
                    imbirem. Część z nas kładzie się do łóżka, odpoczywa i stara
                    porządnie wyspać. Jednak te sposoby nie zawsze są nam w
                    stanie pomóc.
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Druga faza to moment, gdy zaczynamy być bardziej
                    zaniepokojeni naszym stanem. Boli nas głowa, mamy
                    zablokowany nos, odczuwamy drapanie w&nbsp;gardle (na
                    wysokości nosa lub w okolicy krtani) oraz zmagamy się z
                    podrażnioną śluzówką nosa, w&nbsp;której rozwija się stan
                    zapalny. To znak, że organizm został zaatakowany przez jeden
                    z 200 wirusów powodujących{" "}
                    <a href="/slowniczek/#przeziebienie" target="_blank">
                      przeziębienie
                    </a>
                    . Pojawia się trudność w oddychaniu i z odkrztuszaniem
                    wydzieliny. Obfita, wodnista i bezbarwna{" "}
                    <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                      wydzielina
                    </a>{" "}
                    z nosa utrudnia wykonywanie codziennych czynności.
                    Obrzęknięta śluzówka zatyka nos, a my w wyniku tego mówimy
                    zmienionym głosem. Na tym etapie nadmierna ilość śluzu
                    spływa do gardła, co w konsekwencji może prowadzić do
                    wtórnego zakażenia. By pozbyć się uciążliwego intruza, warto
                    szybko podjąć walkę. Zatkany nos i zatoki można oczyścić na
                    kilka sposobów. Jednym z nich jest zastosowanie leków w
                    postaci aerozoli zawierających różne substancje czynne.{" "}
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <div>&nbsp;</div>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    <a href="/slowniczek/#ksylometazolina" target="_blank">
                      Ksylometazolina
                    </a>
                    , zawarta w produktach{" "}
                    <a href="/produkty/sudafed_xylospray/" target="_blank">
                      Sudafed<sup>®</sup> XyloSpray
                    </a>{" "}
                    lub{" "}
                    <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                      Sudafed<sup>®</sup> XyloSpray HA
                    </a>{" "}
                    zmniejsza{" "}
                    <a
                      href="/slowniczek/#obrzek_blony_sluzowej"
                      target="_blank"
                    >
                      obrzęk błony śluzowej
                    </a>{" "}
                    nosa oraz ułatwia odpływ zalegającej wydzieliny. W sytuacji,
                    gdy mamy wysuszoną śluzówkę lepszym rozwiązaniem będzie{" "}
                    <a href="/produkty/sudafed_xylospray_ha/" target="_blank">
                      Sudafed<sup>®</sup> XyloSpray HA
                    </a>
                    , który zawiera aż trzy substancje nawilżające:{" "}
                    <a href="/slowniczek/#glicerol" target="_blank">
                      glicerol
                    </a>
                    ,{" "}
                    <a href="/slowniczek/#sorbitol" target="_blank">
                      sorbitol
                    </a>{" "}
                    i{" "}
                    <a href="/slowniczek/#kwas_hialuronowy" target="_blank">
                      kwas hialuronowy
                    </a>{" "}
                    (w postaci soli sodowej) oraz nie podrażnia błony śluzowej
                    nosa, gdyż nie zawiera konserwantów. Z kolei dla osób, które
                    preferują dyskretny sposób przyjmowania leków, dobrym
                    rozwiązaniem są{" "}
                    <a href="/produkty/sudafed_tabletki/" target="_blank">
                      tabletki Sudafed<sup>®</sup>
                    </a>
                    . Udrażniają one nos (łagodzą obrzęk, zmniejszają ilość
                    zalegającej w górnych drogach oddechowych wydzieliny), a w
                    następstwie tego ułatwiają oddychanie i eliminują uczucie
                    ucisku w głowie i zatokach<sup>1</sup>.&nbsp;
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <img
                    alt="Dobierz leki na przeziębienie zwracając uwagę na swoje symptomy."
                    src="/web/kcfinder/uploads/images/1226065553.jpg"
                    style={{
                      height: "170px",
                      float: "right",
                      margin: "5px 0px 10px 10px",
                      border: "2px solid rgb(255, 255, 255)",
                      width: "250px",
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Trzecia faza przeziębienia objawia się wzrostem temperatury
                  (stan podgorączkowy) i kaszlem. Po około pięciu, sześciu
                  dniach przeziębienia pojawia się także ból mięśni i stawów.
                  Towarzyszy mu uczucie "łamania w kościach" i ból całego ciała.
                  Osłabienie organizmu utrudnia codzienne funkcjonowanie,
                  pojawia się dyskomfort podczas pochylania się, proste
                  czynności wykonujemy znacznie dłużej, a uprawianie sportów
                  staje się niemożliwe. Zmaganie się z podwyższoną temperaturą,
                  przyśpieszonym tętnem i poceniem się oraz rumieńce na
                  policzkach – wszystkie te objawy są oznaką naszego
                  pogarszającego się stanu. W takiej sytuacji należy
                  skonsultować się z lekarzem.&nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    W czwartej fazie przeziębienia nadal walczymy z silnym
                    katarem i przekrwieniem śluzówki nosa, jednak objawy powoli
                    ustępują. Powrót do zdrowia następuje zwykle po około 8-9
                    dniach. Wraca lepsze samopoczucie, energia, mija katar i
                    zaczynamy mówić „swoim” głosem. Podrażnione gardło powoli
                    przestaje drapać, błona śluzowa stopniowo się obkurcza,
                    znika też gorączka, kaszel i wracają nam siły do działania.
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    1 Dotyczy uczucia ucisku w zatokach spowodowanego
                    nagromadzoną wydzieliną i obrzękiem błony śluzowej
                    nosa.&nbsp;
                  </span>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <span className="s1">Bibliografia:</span>
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    Deep Dive into Upper Respiratory Health in Poland” Incite,
                    2011 - Badanie konsumenckie dla Johnson and Johnson.
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    Tylżanowska-Kisiel Joanna, Przeziębienie i grypa, Helion,
                    2007.
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Podobne artykuły</p>
                <a
                  href="/poradnik/przeziebienie/domowe_sposoby_na_walke_z_przeziebieniem"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/artykuly/GettyImages-1030072086.jpg")',
                    }}
                  />
                  <span className="title">
                    Domowe sposoby na walkę z przeziębieniem
                  </span>
                </a>
                <a
                  href="/poradnik/przeziebienie/jak_uchronic_sie_przed_przeziebieniem"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/88688757.jpg")',
                    }}
                  />
                  <span className="title">
                    Jak uchronić się przed przeziębieniem?
                  </span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
